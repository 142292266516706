<template>
  <design-page class="DesignPageAboutUs">
    <div class="BodyAboutUsSolutions">
      <div class="CenterContainerAboutUs">
        <div class="ContainerLeftAboutUs" id="ContainerLeft">
          <div>
            <h1>
              Nuestra Historia
            </h1>
            <h2>
              <p>
                Del Rio Solutions se constituye en el año 2008, dedicada a
                ofrecer Soluciones Informáticas Integrales y de
                Telecomunicaciones para las empresas.
              </p>
              <p>
                Con 8 años de experiencia ofrecemos soluciones integrales,
                además los requerimientos en su organización. No contamos con
                mas sucursales o filiales, la oficina matriz se encuentra solo
                en el Estado de México.
              </p>
            </h2>
            <a href="https://delriosolutions.com.mx/" target="_blank">
              <button class="ButtonAboutUs">
                ¡Vamos Alla!
              </button>
            </a>
          </div>
        </div>
        <div class="ContainerRightAboutUs">
          <div id="AboutUsImage">
            <img class="AboutUsLogo" src="../assets/img/Logo1.png" />
          </div>
        </div>
      </div>
    </div>
  </design-page>
</template>

<script>
import DesignPage from "../components/DesignPage";

export default {
  name: "MainMenu",
  components: {
    DesignPage,
  },
};
</script>

<style lang="scss" scoped>
.BodyAboutUsSolutions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
.CenterContainerAboutUs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 60%;
}
.ContainerLeftAboutUs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 60%;
  padding: 2%;
}
.CenterContainerAboutUs h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 3.9em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.CenterContainerAboutUs h2 {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.ButtonAboutUs {
  width: 35%;
  height: 55px;
  left: 33%;
  border: none;
  outline: none;
  margin-top: 3%;
  background-color: #2d3436;
  border-radius: 100px;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.25s;
  z-index: 1;
  position: relative;
}
.ButtonAboutUs::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  border-radius: 100px;
  background-image: linear-gradient(to right, #f0f0f0, #f0f0f0);
  transition: all 0.55s;
  z-index: -1;
}
.ButtonAboutUs:hover {
  transform: scale(1.1);
  color: black;
}
.ButtonAboutUs:hover::before {
  height: 100%;
}
.ContainerRightAboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40%;
}
#AboutUsImage {
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 65%;
}
@media (min-width: 200px) and (max-width: 300px) {
  //SmartPhones Pequeños
  .CenterContainerAboutUs {
    position: relative;
    justify-content: center;
    flex-direction: column;
    top: -10px;
    width: 90%;
    height: 70%;
    left: -9.5px;
  }
  .ContainerLeftAboutUs {
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftAboutUs h1 {
    font-size: 2em;
  }
  .ContainerLeftAboutUs h2 {
    font-size: 0.7em;
  }
  .ButtonAboutUs {
    position: absolute;
    top: 100%;
    left: 24%;
    width: 150px;
    height: 55px;
  }
  .ContainerRightAboutUs {
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #AboutUsImage {
    height: 100%;
    width: 60%;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  //SmartPhones Promedio
  .CenterContainerAboutUs {
    position: relative;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 80%;
  }
  .ContainerLeftAboutUs {
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftAboutUs h1 {
    font-size: 2em;
  }
  .ContainerLeftAboutUs h2 {
    font-size: 0.9em;
  }
  .ButtonAboutUs {
    position: absolute;
    top: 86%;
    left: 20%;
    width: 60%;
    height: 55px;
  }
  .ContainerRightAboutUs {
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #AboutUsImage {
    height: 100%;
    width: 40%;
  }
}
@media (min-width: 480px) and (max-width: 660px) {
  //SmartPhones Grandes
  .CenterContainerAboutUs {
    position: relative;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 75%;
  }
  .ContainerLeftAboutUs {
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftAboutUs h1 {
    font-size: 3em;
  }
  .ContainerLeftAboutUs h2 {
    font-size: 1em;
  }
  .ButtonAboutUs {
    position: absolute;
    top: 91%;
    left: 24%;
    width: 55%;
    height: 55px;
  }
  .ContainerRightAboutUs {
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #AboutUsImage {
    height: 100%;
    width: 40%;
  }
}
@media (min-width: 660px) and (max-width: 980px) {
  //IPads/Tablets Promedio
  .CenterContainerAboutUs {
    position: relative;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
  }
  .ContainerLeftAboutUs {
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftAboutUs h1 {
    font-size: 4em;
  }
  .ContainerLeftAboutUs h2 {
    font-size: 1.3em;
  }
  .ButtonAboutUs {
    position: absolute;
    top: 93%;
    left: 26%;
    width: 50%;
    height: 60px;
  }
  .ContainerRightAboutUs {
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #AboutUsImage {
    height: 100%;
    width: 40%;
  }
}
@media (min-width: 980px) and (max-width: 1024px) {
  //IPads/Tablets Grandes
  .CenterContainerAboutUs {
    position: relative;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 70%;
  }
  .ContainerLeftAboutUs {
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .ContainerLeftAboutUs h1 {
    font-size: 5em;
  }
  .ContainerLeftAboutUs h2 {
    font-size: 1.7em;
  }
  .ButtonAboutUs {
    position: absolute;
    top: 850px;
    left: 26%;
    width: 50%;
    height: 60px;
  }
  .ContainerRightAboutUs {
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  #AboutUsImage {
    height: 100%;
    width: 40%;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  //Ajuste de boton MotoG4 y otros
  .ButtonAboutUs {
    top: 87%;
    left: 23%;
  }
}
@media (min-width: 411px) and (max-width: 411px) {
  //Ajuste de boton Pixel2 y otros
  .ButtonAboutUs {
    top: 88%;
    left: 23.99%;
  }
}
@media (min-width: 414px) and (max-width: 414px) {
  //Ajuste de boton IPhone 6.7.8 plus y otros
  .ButtonAboutUs {
    top: 90%;
    left: 23.99%;
  }
}
@media (min-width: 320px) and (max-width: 320px) {
  //IPhone 5/SE, resolucion especifica.
  .CenterContainerAboutUs {
    height: 70%;
  }
  .ContainerLeftAboutUs h1 {
    font-size: 1.5em;
  }
  .ContainerLeftAboutUs h2 {
    font-size: 0.7em;
  }
  .ButtonAboutUs {
    top: 90%;
    left: 22%;
  }
}
@media (min-height: 667px) and (max-height: 667px) {
  //IPhone 6,7,8, resolucion especifica
  .ButtonAboutUs {
    height: 55px;
    left: 23%;
    top: 90%;
  }
}
</style>
